<script setup lang="ts">
const { t } = useI18n()
const total = ref(0)
const isLoading = ref(false)

const { setActiveCollection, mainCollections, activeMainCollectionSlug } = useMainCollections()

useHead({
  title: computed(() => t('layouts.title', [t('pages.title.index')])),
})
</script>

<template>
  <div id="page-content-wrapper">
    <section style="--layout-header-height:136px" class="hidden sm:block">
      <div class="relative mx-auto mb15 h-[calc(100vh-var(--layout-header-height,0)-100px)] min-h-[470px] wfull">
        <div class="hfull flex flex-nowrap touch-manipulation snap-x snap-mandatory overflow-x-auto overflow-y-hidden no-scrollbar children:min-w-full">
          <div>
            <div data-testid="card-0" class="relative hfull after:absolute after:inset-0 after:from-[rgba(0,0,0,0.02)] after:via-[rgba(0,0,0,0.04)_50%] after:via-[rgba(0,0,0,0.2)_60%] after:to-[rgba(0,0,0,0.4)] after:bg-gradient-to-b after:content-['']">
              <div class="relative hfull wfull">
                <img
                  height="100%"
                  width="100%"
                  decoding="async"
                  importance="low"
                  fetchpriority="low"
                  loading="lazy"
                  sizes="calc(100vw / 1)"
                  src="/images/desktop.jpg"
                  data-testid="browseCardImage-image"
                  alt="Browse card image"
                  class="inline hfull wfull rounded-0 object-cover object-center-top align-bottom"
                >
              </div>

              <a data-testid="bannerTarget" class="absolute inset-0 z1" href="#" />

              <div class="pointer-events-none absolute inset-x-0 bottom-20 z1 flex flex-col items-center justify-center text-center">
                <span class="mb2 text-sm text-white fw700 uppercase">
                  Stylish hoodies, trendy tracksuit and seamless sports sets.
                </span>
                <span class="text-5xl text-white fw900 leading-13.5">
                  New collection just dropped!
                </span>
                <div class="pointer-events-auto mt7 flex flex-wrap justify-center gap2">
                  <UiButton
                    data-testid="ctaLink"
                    aria-label="Shop Now"
                    variant="outline"
                  >
                    Shop Now
                    <div class="sc-122ag38-1 jgtUPf" />
                  </UiButton>
                </div>
              </div>
            </div>

            <!--            <div> -->
            <!--              <div data-testid="card-1" ></div> -->
            <!--            </div> -->
          </div>
        </div>
      </div>
    </section>

    <section class="relative h-[calc(59px+100vw)] bg-black sm:hidden">
      <section class="absolute inset-x-0 top-2 z1 h8 flex justify-center">
        <a
          v-for="(collection, index) in mainCollections" :key="index"
          href="#"
          data-testid="link"
          :data-active="activeMainCollectionSlug === collection.slug"
          class="relative hfull min-w-13 flex items-center justify-center px2.5 py0 text-sm text-slate-300 fw600 leading-4.5 uppercase transition-colors duration-500 after:absolute after:inset-x-0 after:m-auto after:h0.5 after:w8 after:scale-0 after:bg-white after:transition-transform after:duration-500 after:content-[''] after:-bottom-px"
          :class="{
            'text-white fw900 after:scale-100': activeMainCollectionSlug === collection.slug,
          }"
          @click.prevent="setActiveCollection(collection.slug)"
        >
          {{ collection.name }}
        </a>
      </section>

      <div>
        <div class="absolute inset-0 hfull wfull bg-black">
          <div class="hfull flex flex-nowrap animate-[500ms_ease-in-out_0s_1_normal_none_running_fadeEnter] touch-manipulation snap-x snap-mandatory scroll-p-x-2 overflow-x-auto overflow-y-hidden px2 pb8.75 pt14 no-scrollbar [&>*]:mx1 [&>*]:shrink-0 [&>*]:basis-[calc(100%-16px)] [&>*]:snap-center [&>*]:snap-always first:[&>div]:ml2">
            <div>
              <div>
                <div
                  data-testid="card-0"
                  style="--un-url: '/images/desktop-square.jpg"
                  class="relative hfull before:backface-visible before:absolute before:perspective-1000px before:translate-z-0 before:rounded-lg before:blur-75px before:content-[''] before:-inset-24"
                >
                  <div
                    data-active="true"
                    class="relative z1 aspect-square hfull of-hidden rd-2 after:absolute after:inset-0 after:bg-[linear-gradient(rgba(0,0,0,0.02)_0%,rgba(0,0,0,0.04)_40%,rgba(0,0,0,0.2)_50%,rgba(0,0,0,0.4)_100%)] after:content-['']"
                  >
                    <div class="relative wfull">
                      <img
                        height="100%"
                        width="100%"
                        decoding="sync"
                        importance="high"
                        fetchpriority="high"
                        loading="eager"
                        sizes="calc(100vw / 1)"
                        src="/images/desktop-square.jpg"
                        data-testid="browseCardImage-image"
                        alt="HUGO BLUE"
                        class="aspect-square hfull wfull rd-0 object-cover object-center-center align-bottom"
                      >
                    </div>

                    <a href="#" data-testid="bannerTarget" class="absolute inset-0 z1" />

                    <div class="pointer-events-none absolute inset-x-0 bottom-7 z1 flex flex-col items-center justify-center px4 text-center">
                      <span class="mb2 text-xs text-white fw700 leading-4.5 uppercase">
                        Stylish hoodies, trendy tracksuit and seamless sports sets.
                      </span>
                      <span class="text-6 text-white fw900 leading-7">
                        New collection just dropped!
                      </span>
                      <div class="pointer-events-auto mt5 flex flex-wrap justify-center gap2">
                        <UiButton
                          data-testid="ctaLink"
                          aria-label="Objevit nyní"
                          class="border-white bg-transparent text-white font-bold hover:bg-transparent hover:text-white"
                          variant="outline"
                        >
                          Shop Now
                          <div class="absolute inset-0" />
                        </UiButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <NTinyCarousel :items="3" class="h10 bg-white sm:hidden">
      <template #slide-0>
        <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
          <Icon name="ph:package-light" class="mr2 h5.5 w5.5" /> Free shipping
        </span>
      </template>

      <template #slide-1>
        <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
          <Icon name="ph:arrow-u-down-left" class="mr2 h5.5 w5.5" /> 30 day return policy
        </span>
      </template>

      <template #slide-2>
        <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
          <Icon name="ph:credit-card" class="mr2 h5.5 w5.5" /> secure payments
        </span>
      </template>
    </NTinyCarousel>

    <section class="mb4">
      <div>
        <div class="mx-auto px5.75 op100 transition-opacity duration-250 ease-in-out 1.5xl:max-w-[1440px] 1.5xl:px5.75 lg:px4.75">
          <ProductsGrid
            class="mb27"
            :blocking="false"
            @total="(v) => (total = v)"
            @loading="(loading) => (isLoading = loading)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style>
[data-testid="card-0"]::before {
  --un-url: url(/images/desktop-square.jpg);
  background-blend-mode: overlay, normal;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%) 0% 0% / contain, var(--un-url), 50% center no-repeat lightgray;
}

[data-testid="card-0"]>div {
  @supports (view-timeline-axis:inline) {
    will-change: transform, opacity;
    view-timeline: --cover inline;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    animation-name: cover-flow;
    animation-range: normal;
    animation-timeline: --cover;
  }
}
</style>
